import React from "react"
import { Link } from "gatsby"

import SimpleLayout from "../layouts/SimpleLayout"
import SEO from "../components/seo"

import styles from "./styles/terms.module.css"

const NotFoundPage = () => (
  <SimpleLayout>
    <SEO title="404: Not found" />
    <section className={styles.page}>
      <div className={styles.content}>
        <h1>Página não encontrada</h1>
        <p>Parece que a página a que tentou aceder não existe...</p>
        <Link style={{ color: "#4ebcbc" }} to="/">
          {"< Voltar"}
        </Link>
      </div>
    </section>
    <img
      className={styles.separator1}
      src={require("../img/separator-1.png")}
      alt=""
      style={{ marginBottom: -20 }}
    />
    <div
      style={{
        height: "calc(100vh -( 80px + 560px + 390px))",
        backgroundImage: "linear-gradient(to left, #4ebcbc, #3f55a2)",
      }}
    ></div>
  </SimpleLayout>
)

export default NotFoundPage
